<template>
	<Component
		:is="isIntegrated ? 'div' : 'ZyroPopupCard'"
		class="full"
		:title="$t('builder.colorPicker.chooseColor')"
		is-left-aligned
		:is-absolute="isAbsolute"
		type="editor"
		@close="onClose"
	>
		<ZyroLabel class="full__text">
			{{ $t('builder.colorPicker.websiteColors') }}
		</ZyroLabel>
		<div class="full__colors">
			<ColorButton
				v-for="color in ALL_WEBSITE_COLORS"
				:key="color"
				v-qa="`builder-colorpicker-websitecolor-btn-${color}`"
				:color="transformColorToVariable(color)"
				:selected-color="selectedColor"
				type="rectangular"
				@click="$emit('select-color', $event)"
			/>
		</div>
		<ZyroSeparator class="full__separator" />
		<ZyroLabel class="full__text">
			{{ $t('builder.colorPicker.customColors') }}
		</ZyroLabel>
		<div class="full__custom-colors">
			<ColorButton
				v-for="(color, index) in recentColors"
				:key="`${color}${index}`"
				:color="color"
				:selected-color="selectedColor"
				has-border-radius
				@click="$emit('select-color', $event)"
			/>
			<ModeButton
				:icon-name="CUSTOM_COLOR_ICON"
				:mode="COLOR_PICKER_MODE_CUSTOM_COLOR"
				:show-selected="false"
				:selected-color="selectedColor"
				has-border-radius
				@click="handleModeChange"
			>
				<ColorPicker
					v-if="isIntegrated && isColorPickerVisible"
					class="full__color-picker"
					:selected-color="selectedColor"
					:disable-alpha="disableAlpha"
					is-absolute
					@change-mode="handleModeChange"
					@select-color="$emit('select-color', $event)"
				/>
			</ModeButton>
		</div>
	</Component>
</template>

<script>
import { mapState } from 'vuex';

import ColorPicker from '@/components/global/zyro-color-picker/ColorPicker.vue';
import {
	ALL_WEBSITE_COLORS,
	COLOR_PICKER_MODE_CUSTOM_COLOR,
	COLOR_PICKER_MODE_MINIMAL,
	CUSTOM_COLOR_ICON,
} from '@/components/global/zyro-color-picker/constants';
import ColorButton from '@/components/global/zyro-color-picker/misc/ColorButton.vue';
import ModeButton from '@/components/global/zyro-color-picker/misc/ModeButton.vue';
import { transformColorToVariable } from '@/utils/colorVariableModifiers';

export default {
	components: {
		ColorPicker,
		ModeButton,
		ColorButton,
	},
	props: {
		selectedColor: {
			type: String,
			required: true,
		},
		isAbsolute: {
			type: Boolean,
			default: false,
		},
		isIntegrated: {
			type: Boolean,
			default: false,
		},
		isFull: {
			type: Boolean,
			default: false,
		},
		disableAlpha: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		return {
			ALL_WEBSITE_COLORS,
			COLOR_PICKER_MODE_CUSTOM_COLOR,
			CUSTOM_COLOR_ICON,
			transformColorToVariable,
		};
	},
	data() {
		return { isColorPickerVisible: false };
	},
	computed: { ...mapState('colors', ['recentColors']) },
	methods: {
		handleModeChange(event) {
			if (this.isIntegrated) {
				this.toggleColorPicker();

				return;
			}

			this.$emit('change-mode', event);
		},
		onClose() {
			const closeMode = this.isFull ? null : COLOR_PICKER_MODE_MINIMAL;

			this.$emit('change-mode', closeMode);
		},
		toggleColorPicker() {
			this.isColorPickerVisible = !this.isColorPickerVisible;
		},
	},
};
</script>

<style lang="scss" scoped>
.full {
	&__text {
		margin-bottom: 16px;
	}

	&__colors {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
	}

	&__separator {
		margin: 16px 0;
	}

	&__custom-colors {
		display: flex;
	}

	&__color-picker {
		right: 0;
		bottom: 100%;
	}
}
</style>
